/*import AddLead from "./AddLead";
import { connect } from "react-redux"
import {Link} from 'react-router-dom'
import React, { Component } from 'react'
import axios from 'axios'

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
          refreshingToken: false, // State to track if token refresh is in progress
        };
      }
    
	componentDidMount() {
		//const intervalInMilliseconds = 45 * 60 * 1000;
		const intervalInMilliseconds = 30000;
        // Call function to check and refresh token when the component mounts
        this.checkAndRefreshToken();
        // Set interval to check and refresh token every second
        this.refreshTokenInterval = setInterval(this.checkAndRefreshToken, intervalInMilliseconds);
    }
    
    componentWillUnmount() {
        // Clear the interval when the component unmounts to prevent memory leaks
        clearInterval(this.refreshTokenInterval);
    }
    
      // Function to check and refresh the token
      checkAndRefreshToken = async () => {
        try {
          
			const userId = this.props.auth.id; // Assuming 'id' is the property containing the user ID
            if (userId) {
                await axios.get(`/api/v1/refreshtoken/check-refresh-token/${userId}`);
                console.log('Token refreshed successfully at:', new Date());
				console.log('Token refreshed successfully at:', this.props.auth.id);
            } else {
                console.error('User ID not found in Redux state');
            }
		   
        } catch (error) {
          console.error('Error refreshing token:', error);
        }
      };
  
    renderContent() {
		console.log('renderContent');
        console.log(this.props.auth);
		
        switch (this.props.auth) {
            case null:
			case false:
                return (
                    <li className="nav-item"><a href="/api/v1/auth/google">Login with Google</a></li>
                )
            default:
                return [
                    <li className="nav-item" key={1}><a href="/api/v1/auth/logout">Logout</a></li>
                ]

        }

    }
    
    render() {
        return (

            <div>
				<nav className="navbar navbar-light bg-light">
					<div className="container">
					   
						<Link to="/" className="brand-logo">Logo MP</Link>
						
						<ul className="right">
							 {this.renderContent()}
						 </ul>
					</div>
				</nav> 
            </div>
        )
    }
}

const mapStateToProps = ({ auth }) => {
    return { auth }
}

export default connect(mapStateToProps)(Header)

*/

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import axios from 'axios';

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            refreshingToken: false, // State to track if token refresh is in progress
        };
    }

    componentDidMount() {
        const intervalInMilliseconds = 30000;
        // Call function to check and refresh token when the component mounts
        this.checkAndRefreshToken();
        // Set interval to check and refresh token every second
        this.refreshTokenInterval = setInterval(this.checkAndRefreshToken, intervalInMilliseconds);
    }

    componentWillUnmount() {
        // Clear the interval when the component unmounts to prevent memory leaks
        clearInterval(this.refreshTokenInterval);
    }

    // Function to check and refresh the token
    checkAndRefreshToken = async () => {
        try {
            const userId = this.props.auth.id; // Assuming 'id' is the property containing the user ID
            if (userId) {
                await axios.get(`/api/v1/refreshtoken/check-refresh-token/${userId}`);
                console.log('Token refreshed successfully at:', new Date());
                console.log('Token refreshed successfully for user:', userId);
            } else {
                console.error('User ID not found in Redux state');
            }
        } catch (error) {
            console.error('Error refreshing token:', error);
        }
    };

    renderContent() {
        console.log('Auth in renderContent:', this.props.auth);

        // Ensure auth is properly checked
        if (this.props.auth === null || this.props.auth === false) {
            return (
                <li className="nav-item"><a href="/api/v1/auth/google">Login with Google</a></li>
            );
        } else {
            return (
                <li className="nav-item" key={1}><a href="/api/v1/auth/logout">Logout</a></li>
            );
        }
    }

    render() {
        console.log('Auth in Header render:', this.props.auth);

        return (
            <div>
                <nav className="navbar navbar-light bg-light">
                    <div className="container">
                        <Link to="/" className="brand-logo">Logo MP</Link>
                        <ul className="right">
                            {this.renderContent()}
                        </ul>
                    </div>
                </nav>
            </div>
        );
    }
}

const mapStateToProps = ({ auth }) => {
    return { auth };
};

export default connect(mapStateToProps)(Header);
